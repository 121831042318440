<template>
  <div class="index-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{contentForm.title}}</el-breadcrumb-item>
      <el-breadcrumb-item>会议内容管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-alert style="margin-bottom: 20px;" title=" 还未设置导航菜单" type="error" description="必须设置导航菜单后，会议才会显示内容" show-icon v-if="!isNav"></el-alert>
      <div class="menu-list">
        <div class="menu-item" @click="enterItem('nav')">
          <img src="../../../../assets/img/shell/content/menus.gif"/>
          <div class="title">导航菜单</div>
        </div>
        <div class="menu-item" @click="enterItem('contacts')">
          <img src="../../../../assets/img/shell/content/contacts.gif"/>
          <div class="title">联系人管理</div>
        </div>
        <div class="menu-item" @click="enterItem('traffic')">
          <img src="../../../../assets/img/shell/content/traffic.gif"/>
          <div class="title">交通路线</div>
        </div>
        <div class="menu-item" @click="enterItem('guest')">
          <img src="../../../../assets/img/shell/content/guest.gif"/>
          <div class="title">嘉宾管理</div>
        </div>
        <div class="menu-item" @click="enterItem('article')">
          <img src="../../../../assets/img/shell/content/article.gif"/>
          <div class="title">文章管理</div>
        </div>
        <div class="menu-item" @click="enterItem('article-message')">
          <img src="../../../../assets/img/shell/content/article.gif"/>
          <div class="title">文章留言</div>
        </div>
        <div class="menu-item" @click="enterItem('schedule')">
          <img src="../../../../assets/img/shell/content/schedule.gif"/>
          <div class="title">日程安排</div>
        </div>
        <div class="menu-item" @click="enterItem('live')">
          <img src="../../../../assets/img/shell/content/live.gif"/>
          <div class="title">会议直播</div>
        </div>
        <div class="menu-item" @click="enterItem('video')">
          <img src="../../../../assets/img/shell/content/video.gif"/>
          <div class="title">会议视频</div>
        </div>
        <div class="menu-item" @click="enterItem('photos')">
          <img src="../../../../assets/img/shell/content/photos.gif"/>
          <div class="title">图片直播</div>
        </div>
        <div class="menu-item" @click="enterItem('clockin')">
          <img src="../../../../assets/img/shell/content/clockin.png"/>
          <div class="title">拍照打卡</div>
        </div>
        <div class="menu-item" @click="enterItem('users')">
          <img src="../../../../assets/img/shell/content/users.png"/>
          <div class="title">用户列表</div>
        </div>
        <div class="menu-item" @click="enterItem('download')">
          <img src="../../../../assets/img/shell/content/file-download.gif"/>
          <div class="title">文件下载</div>
        </div>
        <div class="menu-item">
          <img src="../../../../assets/img/shell/content/ticket.gif"/>
          <div class="title">门票核销(开发中...)</div>
        </div>
        <div class="menu-item" @click="enterItem('certificate')">
          <img src="../../../../assets/img/shell/content/certificate.jpg"/>
          <div class="title">证件打印</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      isNav: false,
      contentForm: {
        title: '',
        start_time: '',
        end_time: '',
        copyright: '',
        is_register: 2,
        auto_check: 1,
        auto_ticket: 1,
        bg_img: '',
        head_layout: 1,
        head_txt: '',
        head_img: '',
        head_txt_color: 'rgba(0,0,0,1)',
        nav_color: 'rgba(0,0,0,1)',
        copyright_color: 'rgba(0,0,0,1)',
        bg_music: '',
        wx_img: '',
        wx_title: '',
        wx_content: ''
      }
    }
  },
  created () {
    // 获取信息
    this.getEditItem()
    // 判断是否已设置了导航菜单
    this.getNavList()
  },
  methods: {
    // 获取会议基础设置信息
    async getEditItem () {
      const { data: res } = await this.$http.get('/shell-meeting/' + this.$route.params.mId)
      if (res.status === 200) {
        this.contentForm = res.data
        if (res.data.start_time === 0 || res.data.start_time === '') {
          this.contentForm.start_time = ''
        } else {
          this.contentForm.start_time = res.data.start_time * 1000
        }
        if (res.data.end_time === 0 || res.data.end_time === '') {
          this.contentForm.end_time = ''
        } else {
          this.contentForm.end_time = res.data.end_time * 1000
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取导航列表
    async getNavList () {
      const { data: res } = await this.$http.get('/shell-nav-list', { params: { mId: this.$route.params.mId } })
      if (res.status === 200) {
        if (res.data.length > 0) {
          this.isNav = true
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    // 进入设置项
    enterItem (path) {
      console.log(this.$route.params)
      this.$router.push({ path: '/admin/apps/shell/content/' + path + '/' + this.$route.params.mId, query: { itemName: this.contentForm.title } })
    }
  }
}
</script>

<style scoped>
.page-content{
  text-align: left;
}
.menu-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.menu-list .menu-item{
  margin: 0 20px 20px 0;
  text-align: center;
  background-color: #FFFFFF;
  cursor: pointer;
  transition: box-shadow .3s,border-color .3s;
}
.menu-list .menu-item:hover{
  border-color: rgba(19, 206, 102, 0.15);
  box-shadow: rgba(19, 206, 102, 0.15) 0px 48px 100px 0px;
}
.menu-list .menu-item:hover .title{
  color:rgb(19, 206, 102);
}
.menu-list .menu-item img{
  width: 140px;
  height: 140px;
}
.menu-list .menu-item .title{
  font-size: 13px;
  padding: 10px;
  transition: color .3s;
}
</style>
